/* You can add global styles to this file, and also import other style files */
:root {
    --eden-orange: #fc7138;
    --eden-green: #66b7a1;
    --eden-beige: #e8e4df;
    --eden-light-green: #ecf7f4;
    --eden-blue: #6d86c3;
    --eden-yellow: #fbbe3f;
    --eden-brown: #604b41;
}

body { 
    margin: 0px; 
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
} 

.clickable:active {
    box-shadow: 1px 1px 5px rgb(128, 126, 126);
    background-color: rgb(215, 250, 215);
    border-color: black;
    transition: 0.1s;
}

.pointer {
    cursor: pointer;
}

.tooltip {
    font-family: 'Quicksand';
}

.clear-image-text {
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

.bold {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}

.italics {
    font-style: italic;
}

.orange {
    color: #fc7138;
}

.centered {
    text-align: center;
}

.turq-text {
    color: #66b7a1;
}

@font-face {
    font-family: Vallentina;
    src: url("assets/Vallentina.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: Love;
    src: url("assets/Love.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: Hello;
    src: url("assets/Hello.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: Butterfly;
    src: url("assets/Butterfly.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: Augustus;
    src: url("assets/AUGUSTUS.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: Goldplay;
    src: url("assets/Goldplay-Bold.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: GoldplayMedium;
    src: url("assets/Goldplay-Medium.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: Apricots;
    src: url("assets/Apricots-Regular.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: Now;
    src: url("assets/now.regular.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: NowBold;
    src: url("assets/now.bold.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: Agrandir;
    src: url("assets/Agrandir.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: Maragsa;
    src: url("assets/Maragsa.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'Glacial';
    src: url("assets/GlacialIndifference-Regular.otf") format("opentype");
    font-display: swap;
}